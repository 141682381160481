
import Vue from 'vue';
import BaseButton from '~/components/BaseButton.vue';
import BaseIcon from '~/components/BaseIcon.vue';

export default Vue.extend({
  name: 'SliderVideoComponent',
  components: {BaseButton, BaseIcon},
  props: {
    headline: {
      type: String,
    },
    subline: {
      type: String,
    },
    buttonLabel: {
      type: String,
    },
    previewVideo: {
      type: Object,
    },
    video: {
      type: Object,
    },
  },
  data() {
    return {
      playVideo: false,
    };
  },
  methods: {
    startVideo() {
      this.playVideo = true;
    },
  },
});
